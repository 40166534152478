<template>
  <div class="col-12 col-md-8 mx-auto">
    <div class="row">
      <div class="col">
        <h1>Add Credit Card</h1>
      </div>
    </div>
    <div class="card theme-card shadow-card">
      <div class="card-body">
        <div>
          <form @submit.prevent="submit" id="payment-form" validate>
            <label class="note-info">
              Please enter your Card information
            </label>
            <StripeCard />
            <div class="buttons">
              <save :saving="isSaving" type="submit"> Add Credit Card </save>
              <button
                v-if="src"
                class="btn btn-link ml-2"
                @click.prevent="navigateBack"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StripeCard from "@/components/stripe/StripeCard.vue";
import Swal from "sweetalert2";

export default {
  components: {
    StripeCard,
  },
  data() {
    return {
      cardElement: undefined,
      isSaving: false,
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
    }),
    src: function () {
      return this.$route.query.src;
    },
  },
  methods: {
    ...mapActions({
      storeCard: "creditCards/storeCard",
    }),
    async submit() {
      try {
        const stripElement = document.querySelector("#card-element");
        if (
          stripElement &&
          !stripElement.classList.contains("StripeElement--complete")
        ) {
          Swal.fire({
            title: "Invalid Payment Method",
            html: `Please enter a valid and complete card information.`,
            icon: "error",
            confirmButtonText: "OK",
          });
          return;
        }
        this.isSaving = true;
        const { paymentMethod } = await window.stripe.createPaymentMethod({
          type: "card",
          card: window.cardElement,
        });
        await this.tokenCreated(paymentMethod);
      } catch (err) {
        Swal.fire({
          title: "Error",
          text:
            ((err || {}).error || {}).message ||
            "Please make sure card info is filled properly",
          icon: "error",
        });
      } finally {
        this.isSaving = false;
      }
    },
    tokenCreated(paymentMethod) {
      return this.storeCard({
        payment_method_id: paymentMethod.id,
        user_id: this.patient.user_id,
        patient_id: this.patient.id,
      });
    },
    navigateBack: function () {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.patient == null) {
      this.$router.back();
    }
  },
};
</script>
<style>
.buttons {
  margin-top: 20px;
}

.note-info {
  font-size: 17px;
  margin-bottom: 10px;
}
</style>
